import './PageSectionViewAlternator.scss';

import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { ContentSection, Edges, Product } from '../../../types/wordpress.types';
import PageSectionFrontPageSectionView from '../../PageSectionFrontPageSectionView/PageSectionFrontPageSectionView';
import { LinkTagConfig } from '../../../types/appContentConfig.types';
import BaseLink from '../../BaseLink/BaseLink';
import BaseButton from '../../BaseButton/BaseButton';
import BaseIcon from '../../BaseIcon/BaseIcon';
import APP_CONTENT_CONFIG from '../../../constants/appContentConfig.constants';
import PageSectionHeader from '../../PageSectionHeader/PageSectionHeader';

export const PageSectionFrontPageContentSectionFragment = graphql`
  fragment PageSectionFrontPageContentSectionFragment on WpTemplate_FrontPage {
    frontPageFields {
      contentSections {
        fieldGroupName
        body
        heading
        subheading
        type
        appIconName
        actionButtonLink {
          target
          title
          url
        }
        featuredProduct {
          ... on WpProduct {
            productFields {
              subheading
              relatedMedia {
                ...Image_MediaItem
              }
            }
            ...ProductWithFeaturedImageFragment
          }
        }
      }
    }
  }
`;

type PageSectionViewAlternatorProps = {
  contentSections: ContentSection[],
  headerText?: string,
  headerButton?: LinkTagConfig,
}

const PageSectionViewAlternator: React.FC<PageSectionViewAlternatorProps> = props => {
  const { tourTripsData } = useStaticQuery(graphql`
  {
    tourTripsData: allWpProduct(
      filter: {terms: {nodes: {elemMatch: {slug: {eq: "tours-trips"}}}}, status: {eq: "publish"}}
      sort: {order: ASC, fields: date}
    ) {
      edges {
        node {
          productFields {
            subheading
          }
          ...ProductWithFeaturedImageFragment
          ...ProductFieldsForToursFragment
        }
      }
    }
  }
`);

  const tourTrips: Edges<Product>[] = tourTripsData.edges;

  const p = props;

  const { contentAlignment } = APP_CONTENT_CONFIG.page.frontPage.sectionViewAlternator.sectionFrontPageView;
  return <div className="PageSectionViewAlternator">
    <PageSectionHeader
      className="container"
      text={p.headerText}
      buttonConfig={p.headerButton}
    />
    {tourTrips.map((tour, idx) => {
      const alignment = idx % 2 === 0 ? contentAlignment[0] : contentAlignment[1];
      return (<div
        key={idx}
        className="front-page-content-section-wrapper"
      // data-content-alignment={alignment}
      >
        <PageSectionFrontPageSectionView id={`ViewAlternator-TourTrips-${idx}`} product={tour.node} contentPosition={alignment} />
      </div>)
    })}
    {p.contentSections.map((section, idx) => {
      const idxOffset = Boolean(tourTrips.length) ? tourTrips.length + 1 : 0;
      const alignment = (idx + idxOffset) % 2 === 0 ? contentAlignment[0] : contentAlignment[1];
      return (<div
        key={idx}
        className="front-page-content-section-wrapper"
      // data-content-alignment={alignment}
      >
        <PageSectionFrontPageSectionView id={`ViewAlternator-Content-${idx}`} contentSection={section} contentPosition={alignment} />
      </div>)
    })}
  </div>

}

export default PageSectionViewAlternator;