import { Observer } from 'mobx-react-lite';
import React from 'react';
import APP_CONTENT_CONFIG from '../../constants/appContentConfig.constants';
import { WpContentSection, WpProduct } from '../../types/wordpress.types';
import joinClassNames from '../../utils/className.utils';
import PageSectionContentOnImage from '../PageSectionContentOnImage/PageSectionContentOnImage';
import WishlistButton from '../WishlistButton/WishlistButton';
import './PageSectionFrontPageSectionView.scss';

// Use this with either contentSection or product only, not both.
// contentSection takes precedent over product.
type PageSectionFrontPageSectionViewProps = {
  id: string,
  className?: string,
  contentSection?: WpContentSection,
  product?: WpProduct,
  contentPosition: 'left' | 'right',
}

const PageSectionFrontPageSectionView: React.FC<PageSectionFrontPageSectionViewProps> = props => {

  const p = props;
  const s = {
    get id() {
      return p.contentSection ? p.contentSection?.fieldGroupName : p.product?.id ?? false;
    },
    get heading() { // aka title
      return p.contentSection?.heading ?? p.product?.title ?? "";
    },
    get subheading() {
      return p.contentSection?.subheading ?? p.product?.productFields?.subheading ?? "";
    },
    get excerpt() {
      return p.product?.excerpt ?? "";
    },
    get body() {
      return p.contentSection?.body ?? p.contentSection?.featuredProduct?.content ?? p.product?.content ?? s.excerpt;
    },
    get icon() {
      return p.contentSection?.appIconName ?? "";
    },
    get featuredProduct() {
      return p.contentSection?.featuredProduct ?? p.product?.fieldsForTours?.featuredSubproduct ?? p.product;
    },
    get featuredProductImage() {
      return s.featuredProduct?.featuredImage?.node ?? p.contentSection?.featuredImage;
    },
    get actionLink() { return p.contentSection?.actionButtonLink ?? p.product?.productFields?.actionLink },
    get productRelatedMedia() { return s.featuredProduct?.productFields.relatedMedia }
  }

  const { sectionFrontPageView } = APP_CONTENT_CONFIG.page.frontPage.sectionViewAlternator;
  return <Observer children={() => (
    s.id ? <PageSectionContentOnImage
      id={`FrontPageContentSection-${p.id}`}
      className={joinClassNames("FrontPageContentSection", p.className)}
      contentPosition={p.contentPosition}
      image={{
        media: s.featuredProductImage,
        altText: s.featuredProduct?.title,
      }}
      imageCaption={sectionFrontPageView.figcaption.enabled ? {
        Icon: sectionFrontPageView.figcaption.Icon,
        caption: s.featuredProduct?.title,
      } : undefined}
      content={{
        Icon: s.icon && sectionFrontPageView.IconFactory(s.icon),
        heading: s.heading,
        subheading: s.subheading,
        HeaderAndBodySeparator: sectionFrontPageView.headerAndBodySeparator,
        body: s.body,
        relatedMedia: s.productRelatedMedia,
        actionLink: s.actionLink,

        NonConfigurableContent: <>
          {s.id && p.product &&
            <footer className="front-page-content-section__footer">
              <div className="standalone-wishlist-button-wrapper">
                <WishlistButton product={p.product} alwaysShowText />
              </div>
            </footer>
          }
        </>,
      }}
    /> : null
  )} />

}

export default PageSectionFrontPageSectionView;